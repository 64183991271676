*:focus {
  outline: none;
}
body,
html {
  color: #000;
}
.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) repeat(2, 2fr) repeat(5, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 14px;
}

.div1 {
  grid-area: 1 / 2 / 4 / 3;
  .image-container img {
    object-position: right top;
  }
}
.div2 {
  grid-area: 4 / 2 / 10 / 3;
  .image-container img {
    object-position: right bottom;
  }
}
.div3 {
  grid-area: 2 / 1 / 9 / 2;
  .image-container img {
    object-position: left;
  }
}
.form-parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr) 3fr;
  grid-column-gap: 22px;
  grid-row-gap: 12px;
}

.div4 {
  grid-area: 1 / 1 / 2 / 3; /* Spanning 2 columns */
}
.div5 {
  grid-area: 1 / 3 / 2 / 5; /* Spanning 2 columns */
}
.div6 {
  grid-area: 2 / 1 / 3 / 3; /* Spanning 2 columns */
}
.div7 {
  grid-area: 2 / 3 / 3 / 5; /* Spanning 2 columns */
}
.div8 {
  grid-area: 3 / 1 / 4 / 5; /* Spanning all 4 columns */
}
.dropdown-field {
  width: 97%; /* Make textarea full-width */
  background: #00000000;
  box-sizing: border-box; /* Include padding and border in width/height */
  resize: vertical; /* Allow vertical resizing */
  /* Add any other textarea styles here */
}
a {
  cursor: pointer;
}
.name-field,
.email-field,
.number-field,
.message-field {
  width: 100%; /* Make textarea full-width */
  padding: 14px; /* Adjust textarea padding */
  border: 1px solid #000000; /* Change textarea border color */
  border-radius: 1px; /* Add border radius for rounded corners */
  background: #00000000;
  box-sizing: border-box; /* Include padding and border in width/height */
  resize: vertical; /* Allow vertical resizing */
  /* Add any other textarea styles here */
}

/* Customize error messages */
.errors {
  border: 1px solid red; /* Change textarea border color */
  /* Add any other error message styles here */
}
// Sites Wrapper
.wrapper {
  max-width: 1600px !important;
  margin: auto !important;

  // Big Screens
  padding-left: 170px !important;
  padding-right: 170px !important;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  // Phone Screens
  @media (max-width: 768px) {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

.wrapper-insert {
  max-width: 1600px !important;
  margin: auto !important;

  // Big Screens
  padding-left: 30px !important;
  padding-right: 30px !important;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  // Phone Screens
  @media (max-width: 768px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.grid-container {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1025px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
// gradient-COLORNAME
.default-class {
  /* Default styles for the element */
  color: white;
  background-color: #081612;
  strong {
    color: #fff;
  }
}
.jerseyRed {
  /* Text color */
  color: #9a4447;
  /* Subheader gradient */
  /* Box background and border */
  background-color: #9a4447;
  border: 1px solid #9a4447;
}
.jerseyRedHeader {
  color: #fff; /* White subHeader */
  background-image: linear-gradient(to right, #9a4447, #bc8487);
  strong {
    color: #fff;
  }
}
.bviYellow {
  /* Text color */
  color: #ebc878;
  /* Subheader gradient */
  /* Box background and border */
  background-color: #ebc878;
  border: 1px solid #ebc878;
}
.bviYellowHeader {
  color: #000; /* Black subHeader */
  background-image: linear-gradient(to right, #ebc878, #f2dda6);
  strong {
    color: #000;
  }
}
.panamaPurple {
  color: #7e7a86;
  /* Subheader gradient */
  /* Box background and border */
  background-color: #362c41;
  border: 1px solid #362c41;
}
.panamaPurpleHeader {
  background-image: linear-gradient(to right, #362c41, #7e7a86);
  color: #fff; /* White subHeader */
  strong {
    color: #fff;
  }
}
.switzerlandGreen {
  color: #8f9e93;
  /* Subheader gradient */
  /* Box background and border */
  background-color: #38543f;
  border: 1px solid #38543f;
}
.switzerlandGreenHeader {
  background-image: linear-gradient(to right, #38543f, #8f9e93);
  color: #fff; /* White subHeader */
  strong {
    color: #fff;
  }
}
.londonGold {
  color: #c29e7c;
  /* Subheader gradient */
  /* Box background and border */
  background-color: #c29e7c;
  border: 1px solid #c29e7c;
}
.londonGoldHeader {
  background-image: linear-gradient(to right, #c29e7c, #e1c3a7);
  color: #fff; /* White subHeader */
  strong {
    color: #fff;
  }
}
// Titles & Headings
h1,
.h1 {
  font-weight: 800;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 65px;
  line-height: 75px;

  margin-bottom: 25px;
  strong {
    color: #b78c68;
    font-family: "didot";
    font-size: 85px;
    font-weight: 100;
    font-style: italic;
    @media (max-width: 1024px) {
      font-size: 80px;
    }
    @media (max-width: 768px) {
      font-size: 50px;
    }
    @media (max-width: 430px) {
      font-size: 40px;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: 10px;
    font-size: 55px;
  }
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 430px) {
    font-size: 36px;
    line-height: 45px;
  }
}

.page-title {
  strong {
    font-style: italic;
  }
}
h2,
.h2 {
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 38px;
  line-height: 40px;
  strong {
    font-family: "didot";
    font-weight: 100;
    font-size: 44px;
    @media (max-width: 730px) {
      font-size: 35px;
    }
    @media (max-width: 430px) {
      font-size: 28px;
    }
  }
  @media (max-width: 730px) {
    font-size: 30px;
  }
  @media (max-width: 430px) {
    font-size: 22px;
    line-height: 32px;
  }
}
.nameHeader {
  font-size: 65px;
  line-height: 65px;
  @media (max-width: 730px) {
    font-size: 35px;
  }
  @media (max-width: 430px) {
    font-size: 28px;
  }
}
h3,
.h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
  font-size: 38px;
  strong {
    font-family: "didot";
    font-weight: 800;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
}
.overlayHeader {
  color: #fff;
  font-size: 48px;
  line-height: 53px;
  font-weight: 700;
  @media (max-width: 600px) {
    line-height: 45px;
    font-size: 35px;
  }
}
.footerheader {
  font-weight: 700;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 38px;
  line-height: 43px;
  margin-bottom: 25px;
  strong {
    color: #b78c68;
    font-family: "didot";
    font-style: italic;
    font-size: 44px;
    font-weight: 100;
    @media (max-width: 1024px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 38px;
    }
    @media (max-width: 430px) {
      font-size: 32px;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: 10px;
    font-size: 35px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 60px;
    line-height: 35px;
  }
  @media (max-width: 430px) {
    font-size: 23px;
    line-height: 50px;
    line-height: 33px;
  }
}
.logoimg {
  width: 133px;
  @media (max-width: 768px) {
    width: 90px;
  }
}
.quote {
  font-size: 38px;
  line-height: 42px;
  text-align: center;
}
.page-heading {
  font-weight: 400;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 50px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    line-height: 50px;
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 40px;
  }
  strong {
    font-family: "didot";
    font-size: 64px;
    @media (max-width: 768px) {
      font-size: 50px;
    }
    @media (max-width: 450px) {
      font-size: 40px;
    }
  }
}
.page-subheading {
  font-size: 16px;
}
.people-location {
  background: #fff;
  transition: 0.5s;
}
.people-location.font-bold {
  color: #f5f3e7;
  background: #2d432f;
}

// Sub Headings
.popupText {
  font-size: 23px;
  font-weight: 300;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
}
.btn-trans {
  transition: 0.5s;
}
.center-screen {
  position: fixed;
  top: 50%; /* Positions the top edge at the vertical center */
  right: 100%; /* Positions the left edge at the horizontal center */
}

.number1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 70px;
  font-weight: 700;
  color: #151515;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 60px;
  }
}
.number2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 70px;
  font-weight: 700;
  color: #b78c68;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 60px;
  }
}
.number3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 70px;
  font-weight: 700;
  color: #733030;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 60px;
  }
}
.small-listing {
  @media (max-width: 430px) {
    font-size: 21px;
  }
}

// Generic Text, buttons, links etc.
p {
  font-weight: 300;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  strong {
    font-weight: 700;
  }
}
.description {
  p {
    font-weight: 300;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    strong {
      font-weight: 700;
    }
  }
  ul {
    list-style: inherit;
    margin-left: 20px;
    li {
      list-style: inherit;
      font-weight: 300;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }
}
.box-text {
  font-size: 50px;
  font-weight: 600;
  line-height: 65px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
  @media (max-width: 430px) {
    font-size: 30px;
  }
}

input,
button {
  font-weight: 500;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 20px;
  outline: none !important;

  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}

li p {
  line-height: 48px;
  font-size: 25px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.footertext {
  font-size: 15px;
  @media (max-width: 375px) {
    font-size: 13px;
  }
}
a {
  font-size: 15px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #717171;
  font-weight: 400;
}
#topicheader {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
}
.servicedesc {
  font-size: 19px;
  line-height: 27px;
}
// fonts
@font-face {
  font-family: "didotbold_italic";
  src: url("fonts/Didot/didot_bold_italic-webfont.woff2") format("woff2"),
    url("fonts/Didot/didot_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "didot_lt_stdbold";
  src: url("fonts/Didot/didot_lt_std_bold-webfont.woff2") format("woff2"),
    url("fonts/Didot/didot_lt_std_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "didot_lt_stditalic";
  src: url("fonts/Didot/didot_lt_std_italic-webfont.woff2") format("woff2"),
    url("fonts/Didot/didot_lt_std_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "didotmedium";
  src: url("fonts/Didot/didot_medium-webfont.woff2") format("woff2"),
    url("fonts/Didot/didot_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "didotregular";
  src: url("fonts/Didot/didot_regular-webfont.woff2") format("woff2"),
    url("fonts/Didot/didot_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.lineimg {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}
.cropped {
  width: 100%; /* Width is flexible based on the container */
  height: 100%; /* Height occupies the full height of the container */
  overflow: hidden; /* Hide the overflow content */
  position: relative; /* Establish a positioning context */
}

.cropped img {
  width: 100%; /* Make the image take up the full container width */
  height: 100%; /* Ensure the image takes up the full container height */
  object-fit: cover; /* Fit the entire image within the container */
  transition: transform 1.5s, opacity 1.5s; /* Add transition effects */
  transform: scale(1); /* Set initial scale */
  display: block; /* Ensure image behaves as a block element */
}

.cropped:hover img {
  transform: scale(1.05) translate(0, -1%); /* Enlarge and move the image upwards on hover */
  opacity: 1; /* Make the image fully visible on hover */
}

.image-container {
  width: 100%;
  /* Set a fixed height or use a percentage to make it responsive */
  min-height: 410px;
  overflow: hidden; /* This will crop the image */
}

.image-container img {
  width: auto; /* Auto width for maintaining aspect ratio */
  height: 100%; /* Full height of the container */
  min-width: 100%; /* Ensures image covers the width of the container */
  object-fit: cover; /* Keeps the image from being stretched or squashed */
  object-position: center; /* Centers the image in the container */
}
.vidCrop {
  width: 100%;
  overflow: hidden;
}

.vidCrop video {
  width: auto;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}

.home-image-container {
  width: 570px;
  height: 570px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden; /* Hide the overflowing parts of the home-image */
  @media (max-width: 520px) {
    height: 360px;
    width: 100%;
  }
}

.staff-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.staff-image-container {
  width: 295px;
  height: 392px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden; /* Hide the overflowing parts of the home-image */
}

.home-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup_crop {
  width: 100%;
  height: 358px;
  overflow: hidden; /* Hide the overflow content */
  object-position: center;
  @media (max-width: 430px) {
    height: 273px;
  }
  @media (max-width: 320px) {
    height: 224px;
  }
}

.popup_crop img {
  width: 150%; /* Make the image take up the full container */
  height: auto; /* Maintain the image's aspect ratio */
  object-fit: cover; /* Crop the image */
  transition: transform 1s;
}

.popup_crop:hover img {
  transform: scale(1.1) translate(0, 1%);
  opacity: 100%;
}
.servicesGrid {
  display: flex;
  flex-direction: column;
}
.btn {
  transition: 0.5s;
  padding: 20px;
}
.hidimg {
  display: block;
  @media (max-width: 1025px) {
    display: none;
  }
}
.hiddenimg {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}
.tabhide {
  display: block;

  @media (max-width: 770px) {
    display: none;
  }
}
.laptophide {
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
}
.laptopshow {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}
.mobilehide {
  display: block;
  @media (max-width: 430px) {
    display: none;
  }
}
.mobileshow {
  display: none;
  @media (max-width: 430px) {
    display: block;
  }
}
.hamburger-nav {
  display: none;
  @media (max-width: 1020px) {
    display: block;
  }
}
/* Style the dropdown */
.dropdown-box {
  width: 120px; /* Set a width */
  padding: 10px; /* Add padding */
  border-radius: 5px; /* Add border radius */
  font-size: 20px; /* Set font size */
  background-color: #000000; /* Set background color */
  color: #ffffff; /* Set text color */
  font-family: "Plus Jakarta Sans", sans-serif;
}

/* Style the dropdown options */
.dropdown option {
  background-color: #000000; /* Set background color */
  color: #333; /* Set text color */
}
.bmBorder {
  border-bottom: 1px solid #fff;
  margin: 20px 0;
}
.hidepopup {
  display: none;
}
/* Adjust the space between table cells */
.table-row {
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Adds space between rows */
}
/* Style the separator line */
.content-cell::after {
  content: "";
  position: absolute;
  top: 50%; /* Position the line in the middle vertically */
  right: 0;
  width: 1px; /* Width of the line */
  height: calc(100% - 10px); /* Height of the line (adjust as needed) */
  background-color: #000000; /* Color of the line */
  transform: translateY(-50%); /* Center the line vertically */
}

td {
  padding-bottom: 26px; /* Adjust the padding inside each cell */
}
.black-line {
  display: flex;

  width: 100%;
  height: 2px;
  background-color: black;
}
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Adjust as needed */
}
.small-centered-content {
  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Adjust as needed */
  }
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 125%;
  right: 0;
  // transform: translateX(-50%);
  padding: 10px;
  z-index: 5;
  background-color: #ffffff;
  color: #565656;
  border-radius: 5px;
  width: 400px;
  font-size: 15px;
  transition: opacity 0.3s, visibility 0.3s;
  @media (max-width: 430px) {
    width: 300px;
    bottom: -538%;
    right: 116%;
  }
  @media (max-width: 430px) {
    width: 300px;
    bottom: -538%;
    right: 0%;
  }
}
.pNText {
  line-height: 23px;
  color: #fff;
}
.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
.flex-wrap-reverse {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
}
.button-position {
  display: block;
  @media (max-width: 430px) {
    position: absolute;
    right: -20px;
    top: 0px;
  }
}

.mob-nav {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none;
  max-width: 500px;
  right: 0;
  .mob-menu {
    transform: translateX(100%);
  }
  @media (max-width: 780px) {
    width: 100%;
  }
}
.mob-nav.open {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: all;
  .mob-menu {
    transform: translateX(0%);
  }
  @media (max-width: 780px) {
    width: 100%;
  }
}
/* Style for entries with categories */
/* Style for entries with categories */
.has-category {
  /* Your styles for entries with categories */
  /* For example, changing text color */
  color: black;
}

/* Style for entries without categories */
.no-category {
  /* Your styles for entries without categories */
  /* For example, changing text color */
  color: white;
}

/* Define the hover effect for the div */
.time-svg-container:hover .hover-fill {
  fill: #b78c68; /* Change this color to the desired hover fill color */
}
.time-svg-container:hover .hover-stroke {
  stroke: #000000; /* Change this color to the desired hover stroke color */
}
.flexCol {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.text-red {
  color: #9a4447;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none; /* Disable interaction when faded out */
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: auto; /* Enable interaction when faded in */
}
.submenu {
  transition: opacity 0.5s ease, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
}

.submenu.fade-in-nav {
  opacity: 1;
  visibility: visible;
  height: 510px;
  width: 1275px;
}

.hidden {
  display: none;
}
.navItem {
  opacity: 1;
  visibility: visible;
  height: calc(100% - 43px);
  .navImg {
    transition: 0.5s;
    opacity: 1;
    height: 100%;
  }
}

.navItem:hover {
  opacity: 1;
  .navImg {
    opacity: 0;
    visibility: hidden;
  }
}

.active-location::after {
  content: "•";
  color: #9a4447; /* Red color */
  display: block; /* Displayed on a new line */
  text-align: center;
  font-size: 20px; /* Adjust the size as needed */
}

.active-category::after {
  content: "•";
  color: #9a4447; /* Red color */
  display: inline-block; /* Displayed inline */
  margin-left: 5px; /* Space between the text and the dot */
}

// x-scroll bar

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.location-item {
  transition: all 0.3s ease;
}

.location-item:hover svg {
  opacity: 1 !important; /* Assuming your SVGs are initially hidden or have lower opacity */
}

.location-item:hover h3,
.category-item:hover p {
  font-weight: bold;
}

.location-item:hover,
.location-item:hover svg,
.location-item:hover h3,
.category-item:hover p {
  cursor: pointer;
}

.people-img {
  filter: grayscale(1);
}
.people-img:hover {
  filter: grayscale(0);
}
button.location-button.centered-content {
  opacity: 0.5;
}
button.location-button.centered-content.active {
  opacity: 1;
}
.full-text {
  a {
    font-size: 18px;
  }
  ul {
    margin-left: 15px;
    list-style: circle;
    li {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
}

// core services

.location-title .location-item {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.location-title h3 {
  font-weight: normal;
  transition: font-weight 0.3s ease;
}

.location-title.active .location-item {
  opacity: 1;
}
.location-title:hover .location-item {
  opacity: 1;
}

.location-title.active h3 {
  font-weight: 600;
}
.location-title:hover h3 {
  font-weight: 600;
}
.category-item .circle {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item p {
  font-weight: normal;
  transition: font-weight 0.3s ease;
}

.category-item.heading-active .circle,
.category-item.heading-active p {
  opacity: 1;
}

.category-item.heading-active p {
  font-weight: 600;
}

// location services

/* Default state, when 'active' class is not present */
.location-button .svg-container svg,
.location-button .circle {
  opacity: 0; /* SVG and circle are initially hidden */
}

/* When 'active' class is present on the button */
.location-button.active .svg-container svg,
.location-button.active .circle {
  opacity: 1; /* SVG and circle are fully visible */
}
.location-button:hover .svg-container svg,
.location-button:hover .circle {
  opacity: 1; /* SVG and circle are fully visible */
}

@media screen and (min-width: 1024px) {
  .leading-0 {
    letter-spacing: -5px;
  }
}
.leading-0 {
  letter-spacing: -3.5px;
}

select.dropdown-box {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px); /* increased from 2px to 4px */
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.custom-button-layout {
  display: flex;
  gap: 2rem; /* default gap size */
  flex-direction: column; /* stacks buttons vertically by default */
  align-items: center; /* optional, for centering the buttons */
}

@media screen and (min-width: 550px) {
  .custom-button-layout {
    flex-direction: row; /* switches to horizontal layout on larger screens */
    gap: 1rem;
  }
}

.border-\[1px\].border-black.py-\[9px\].px-\[7px\].jurisdiction {
  width: 100%;
  padding: 11px !important;
  border: 1px solid #000000;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: vertical;
}

.location-title {
  cursor: pointer;
}

select#sortDropdown {
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.category-button-item {
  font-size: 16px; /* Example font size */
  min-height: 40px; /* Example minimum height */
  display: flex;
  align-items: center;
  width: 140px;
  border-radius: 3xl;
  cursor: pointer;
}

.icon-spacing-fix {
  margin-right: 1px;
}

@media screen and (min-width: 1280px) and (max-width: 1535px) {
  div#hatstone-logo {
    margin-right: 25px !important;
  }
}

.custom-input,
.custom-button {
  font-size: 15px !important;
}

.custom-input {
  background-color: transparent; /* Ensures background is always transparent */
  color: white; /* Text color */
}

.custom-input:focus {
  background-color: transparent; /* Maintains transparent background on focus */
}

.accord-cont {
  .answer {
    display: none;
    opacity: 0;
    transition: opacity 0s 1s;
  }
  .answer.open {
    display: block;
    opacity: 1;
    transition: opacity 1s;
  }
}
.breadcrumb-item {
  a {
    font-size: 16px;
    color: #b78c68;
  }
}
.privacy-copy {
  ol {
    margin-bottom: 1rem;
    list-style-type: inherit;
    margin-left: 20px;
    li {
      list-style-type: inherit;
      font-size: 18px;
      margin-bottom: 1rem;
    }
  }
  a {
    font-size: 18px;
    color: #b78c68;
  }
  ul {
    margin-bottom: 1rem;
    margin-left: 20px;
    list-style-type: inherit;
    li {
      list-style-type: inherit;
      font-size: 18px;
      margin-bottom: 1rem;
    }
  }
}
