// Nav at the top
.headroom--top {
  background: #ffffff00;
  height: 100px;
}

// Nav after scrolling
.headroom--unpinned,
.headroom--pinned,
.headroom {
  background: #08161200;
  height: 100px;
}
.headroom--not-top {
  &.page-bvi {
    background-color: rgba(242, 212, 148, 0.9); // A sunny shade for BVI
  }

  &.page-jersey {
    background-color: rgba(174, 105, 108, 0.9); // A rosy tone for Jersey
  }

  &.page-panama,
  &.page-services {
    background-color: rgba(
      91,
      82,
      99,
      0.9
    ); // A majestic hue for Panama and Services
  }
  &.page-london {
    background-color: rgba(206, 173, 141, 0.9); // A rosy tone for Jersey
  }
  &.page-switzerland {
    background-color: rgba(84, 109, 91, 0.9); // A rosy tone for Jersey
  }

  &.page-default {
    background-color: rgba(26, 46, 37, 0.9); // A deep, rich default
  }

  .md\:max-w-\[130px\] {
    max-width: 90px !important;
  }

  .nav-menu {
    padding-top: 2rem !important;
  }
}

.headroom--not-top.pt-12 {
  padding-top: 1rem !important;
}

.headroom--not-top.max-h-\[170px\] {
  max-height: 105px !important;
  padding-top: 1rem !important;
}
